import { graphql, useStaticQuery } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'

export const Image = props => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "image-18.png" }) {
            childImageSharp {
                fixed(width: 155, height: 205) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
    `)

    return <Img
        fixed={data.file.childImageSharp.fixed}
        {...props}
        style={{
            minWidth: '155px',
            minHeight: '205px',
            ...props.style
        }}
    />
}