import './styles.scss'
import '@Components/Pages/Detailing/styles.scss'

import { Image, MobileImage } from '@Components/Pages/Detailing'

import MenuHeader from '@Components/shared/MenuPieces/MenuHeader'
import React from 'react'

const Detailing = () =>
  <div className='menu-page'>
    <div className='menu-page-detailing'>
      <MenuHeader title={'Detailing'} className='menu-header-detailing' />
      <MobileImage className='menu-page-detailing-img-mobile' alt='' />
      <div className='menu-page-detailing-info'>
        <p>
          Transparency is important to us here at CarVantedge, which is why we are
          offering a detailing coupon with every vehicle purchase. The vehicles we sell
          through this program come from expertly maintained corporate fleets. That
          being said, they sometimes are turned in with less than perfectly cleaned
          interiors and exteriors. To ensure your vehicle buying experience with us is up
          to par, we are including this detailing coupon for your convenience with a value
          up to $125!
        </p>
        <Image className='menu-page-detailing-info-pic' alt='detailing-icon' />
      </div>
      <div className='menu-header-secondary'>How it works:</div>
      <div className='menu-page-detailing-text'>
        <p>
          Once you pick up your vehicle, you may take it to any vehicle detailing company
          of your choice. After your vehicle has been detailed, you can either have the
          company call us and we will pay by credit card directly over the phone or you
          may pay for the detail, send us a copy of your invoice/receipt and we will
          reimburse you for up to $125.
        </p>
        <p>Please feel free to contact us at <a href='tel:8005392277'>(800) 539-2277</a> with
        any questions, comments and/or concerns regarding this offering.
        </p>
      </div>
    </div>
  </div>

export default Detailing
